<template>
  <div
    class="whitespace-pre-wrap overflow-y-auto text-secondary-80 w-full"
    :class="{ '*:!inline line-clamp-4 !overflow-hidden': hideOverflow }"
  >
    <template v-if="typeof parsedDescription !== 'string'">
      <template v-for="(block, block_index) in parsedDescription.content">
        <template v-for="(child, child_index) in block.content">
          <template v-if="child.type === 'text'">
            {{ child.text }}
          </template>
          <VariableChip
            v-else-if="child.type = 'VariableChip'"
            :key="block_index + '_' + child_index"
            :type="child.attrs.type"
            :with-value="enableValues"
            @update:error-state="updateErrorState"
          />
        </template>
        <template v-if="block_index + 1 !== parsedDescription.length">
          {{ '\n' }}
        </template>
      </template>
    </template>
    <template v-else>
      {{ parsedDescription }}
    </template>
  </div>
</template>

<script setup lang="ts">
import { VariableChip } from '../variable-chip';
import { ErrorState } from '~/store/variables-modal';

type Props = {
  description: string;
  enableValues?: boolean;
  hideOverflow?: boolean;
  hideVariablesModal?: boolean;
};

const props = defineProps<Props>();

const errorState = ref<ErrorState>({});
const emit = defineEmits<{
  (e: 'update:error-state'): ErrorState;
}>();

const updateErrorState = ({
  value,
  variableType,
}: {
  value: string;
  variableType: string;
}) => {
  errorState.value[variableType] = value;
  emit('update:error-state', errorState.value);
};

const parsedDescription = computed(() => {
  try {
    return JSON.parse(props.description);
  } catch {
    return props.description;
  }
});
</script>
